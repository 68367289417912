<template>
  <div class="main-profile-container" :dir="locale === 'ara' ? 'rtl' : 'ltr'">
    <!-- <div class="profile-section">
      <SubscriberDetails></SubscriberDetails>
    </div> -->
    <div class="tab-section">
      <ProfileTab
        :key="profileid"
        :locale="locale"
        :isCardUpdate="isCardUpdate"
        :cardUpdateDetailState="cardUpdateDetailState"
        :cardUpdateStatus="cardUpdateStatus"
      ></ProfileTab>
      <!-- <CardUpdate /> -->
    </div>
    <div class="payment_confirmation">
      <!-- <payment v-if="isPayment" :paymentDetail="paymentDetail" :closePopup="() => togglePaymentPopup(false)" /> -->
    </div>
    <div>
      <message v-if="isMessage" :messageData="errorPopupData" :closePopup="() => closePopup()"></message>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
export default {
  name: "Profile",
  data() {
    return {
      profileEnable: false,
      localDisplayLang: false,
      queryParams: null,
      isPayment: false,
      paymentDetail: null,
      isMessage: false,
      errorPopupData: null,
      newSession: null,
      isCardUpdate: null,
      cardUpdateDetailState: null,
      cardUpdateStatus: null,
    };
  },

  // created() {
  //   eventBus.$on("open-rzp-modal", (plan) => {
  //     eventBus.$emit("open-home-rzp-modal", plan);
  //   });
  //   this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

  //   this.newSession = this.sessionToken();

  //   eventBus.$on("check-for-series-route", () => {
  //     this.newSession = this.sessionToken();
  //   });


  // },
  // mounted() {
  //   this.paymentHandler();

  //   eventBus.$on("show-message-popup", (message) => {
  //     if (message) {
  //       this.isMessage = true;
  //       this.errorPopupData = message;
  //     }
  //   });

  //   //stcbh response handler.
  //   eventBus.$on("telcostcbh-response", (response) => {
  //     eventBus.$emit("enableOverlayLoader", false);
  //     if (!response.reason) {
  //       if (
  //         Object.keys(this.queryParams).length > 0 &&
  //         this.queryParams.hasOwnProperty("planid") &&
  //         this.queryParams.hasOwnProperty("referenceid")
  //       ) {
  //         this.showPaymentResponse();
  //       }
  //     } else {
  //       this.errorPopupData = { title: this.$t("error"), message: response.reason };
  //       this.toggleMessagePopup(true);
  //     }
  //   });
  // },
  methods: {
    closePopup() {
      

      if (this.$router.currentRoute.fullPath !== "/profile") {
        this.$router.push("/profile");
      }
      this.toggleMessagePopup(false);
    },
    paymentHandler() {
      const updateCardFlowState = JSON.parse(localStorage.getItem("cardUpdateFlowState"));

      this.queryParams = this.$router.currentRoute.query;

      
      console.log(
        "THE DIRECT ERROR MESSAGE -- JUST CONDITION",
        Object.keys(this.queryParams).length > 0,
        this.queryParams.message
      );

      if (Object.keys(this.queryParams).length > 0 && (this.queryParams.status === "error" || this.queryParams.error)) {
        

        this.errorPopupData = {
          title: this.$t("error"),
          message: `${this.$t("Payment Failed")} : ${
            this.queryParams.message ? this.queryParams.message : this.queryParams.error ? this.queryParams.error : ""
          }`,
        };
        this.toggleMessagePopup(true);
      }
      // --
      else if (Object.keys(this.queryParams).length > 0 && this.queryParams.message) {
        //enable overlay loader.
        eventBus.$emit("enableOverlayLoader", true);
        //call pgclienthandler api.
        let payload = {
          code: this.queryParams.code,
        };
        eventBus.$emit("telcostcbh", payload);
      }

      // ----
      else if (
        Object.keys(this.queryParams).length > 0 &&
        this.queryParams.status &&
        updateCardFlowState &&
        updateCardFlowState.isCardUpdate
      ) {
        this.isCardUpdate = true;
        this.cardUpdateDetailState = updateCardFlowState;
        this.cardUpdateStatus = { title: this.$t("error"), message: this.queryParams.error };
        
      }
      // ----
      // else if (Object.keys(this.queryParams).length > 0 && this.queryParams.status) {
      //   console.log("THE DIRECT ERROR MESSAGE -- ", this.queryParams);

      //   this.errorPopupData = { title: this.$t("error"), message: this.queryParams.error };
      //   this.toggleMessagePopup(true);
      // }

      // ----
      else if (
        Object.keys(this.queryParams).length > 0 &&
        this.queryParams.hasOwnProperty("referenceid") &&
        updateCardFlowState &&
        updateCardFlowState.isCardUpdate
      ) {
        this.isCardUpdate = true;
        this.cardUpdateDetailState = updateCardFlowState;
        this.showPaymentResponse();
        
      }
      // ----
      else if (Object.keys(this.queryParams).length > 0 && this.queryParams.hasOwnProperty("referenceid")) {
        this.showPaymentResponse();
      }
    },
    showPaymentResponse() {
      let referenceid = this.queryParams.referenceid;
      let planid = this.queryParams.planid;

      let payload = {
        referenceid: referenceid,
        detail: planid,
        state: true,
      };

      

      if (this.isCardUpdate) {
        this.cardUpdateStatus = payload;
      } else {
        this.paymentDetail = payload;
        this.togglePaymentPopup(true);
      }
    },
    togglePaymentPopup(state) {
      if (!state) {
        this.$router.replace("/profile");
      }
      
      this.isPayment = state;
    },
    toggleMessagePopup(state) {
      this.isMessage = state;
    },
    sessionToken() {
      return localStorage.getItem("sessionToken");
    },
  },
  computed: {
    ...mapGetters(["getRtl", "profileid"]),
    locale() {
      return this.$i18n.locale;
    },
  },
  components: {
    SubscriberDetails: () => import(/* webpackChunkName: "subscriber" */ "./Subscriber/SubscriberDetails.vue"),
    // OtherInfo: () =>
    //   import(
    //     /* webpackChunkName: "others" */ "./OtherProfiles/OtherUserInfo.vue"
    //   ),
    ProfileTab: () => import(/* webpackChunkName: "tabs" */ "./ProfileTabs/ProfileTab.vue"),
    CardUpdate: () => import(/* webpackChunkName: "tabs" */ "./ProfileTabs/CardUpdate.vue"),
    RzpPayment: () => import(/* webpackChunkName: "payment" */ "@/components/Popups/Payment/RzpPayment.vue"),
    
    message: () => import(/* webpackChunkName: "message" */ "@/components/Popups/message.vue"),
  },
  beforeDestroy() {
    eventBus.$off("telcostcbh-response");
    eventBus.$off("show-message-popup");
    eventBus.$off("open-rzp-modal");
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";

.profile-menu {
  margin: 1rem 0;
  padding: 2.4rem 1.8rem;
  background-color: $clr-dark-gd4;
  border-radius: 15px;
  font-family: $font-regular;

  &__title {
    color: $clr-light-gd1;
    margin: 0 0 1.5rem 0;
  }

  &__button {
    outline: none;
    border: none;
    background: none;

    font-size: 0.8rem;
    color: $clr-light-gd3;
    font-weight: bold;
    cursor: pointer;

    opacity: 0.5;
    &:hover {
      color: $btn-clr;
      // color: $clr-light-gd2; // for nammaflix
      opacity: 1;
    }
  }
}

.main-profile-container {
 // display: grid;
 // grid-template-columns: repeat(12, 1fr);
 // grid-column-gap: 1.2rem;
 // padding: 1.2rem;
  width: clamp(2rem, 100%, 95%);
  margin: auto;
  margin-top: 5rem;
 // .profile-section {
 //   grid-column: 1 / span 3;
 // }
 // .tab-section {
  //  grid-column: 4 / span 12;
  //}
}
@media only screen and (max-width:1000px) {
  .main-profile-container {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .profile-section{
    margin-top: 4rem;
  }
}

</style>

<template>
  <div>
    <div class="multi-lang"> 
      <u class="linguval">{{$t("TERMS OF USE")}} </u>
    </div  >
    <div v-html="dataset"> </div>
 
  </div>
  
  
</template>

<script>
export default {
 data() {
  return {
    defaultThemeColor : '',
    dataset :'',
    lightUrl: 'https://static-templates.web.app/kee/en/termsofuse.html',
    darkUrl: 'https://static-templates.web.app/kee/en/termsofuse.html'
  }
 },
 created () {
    
    eventBus.$on('theme-change', (data) => {
      if (data === 'theme-dark') {
        this.isThemeDark = true;
      } else {
        this.isThemeDark = false;
      }
    });
  },
 mounted () {
  this.defaultThemeColor = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'theme-dark'
    if (this.defaultThemeColor === 'theme-dark') {
        this.isThemeDark = true;
      } else {
        this.isThemeDark = false;
      }
  document.title= "Terms and Conditions: Terms of Use - Kee";   
  var allMetaElements = document.getElementsByTagName('meta');
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  document.body.scrollTop = 0; // For Safari
  if (this.isThemeDark) {
      fetch(this.darkUrl)
        .then(res => res.text())
        .then(html => this.dataset = html);
    } else {
      fetch(this.lightUrl)
        .then(res => res.text())
        .then(html => this.dataset = html);
    }
 }
}
</script>

<style scoped lang="scss">
.multi-lang{
  text-align: center;
  color: #777;
  font-size: 24px !important;
  font-weight:bold;
  margin-top: 4rem;
}
</style>
<template>
  <div>
    <div class="multi-lang-privacy"> 
      <u class="linguval-privacy">{{$t("privacy policy")}} </u>
    </div>  
    <div v-html="dataset"></div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      defaultThemeColor : '',
      dataset :'',
      lightUrl: 'https://static-templates.web.app/kee/en/privacypolicy.html ',
      darkUrl: 'https://static-templates.web.app/kee/en/privacypolicy.html '
    }
  },
  created () {
    
    eventBus.$on('theme-change', (data) => {
      if (data === 'theme-dark') {
        this.isThemeDark = true;
      } else {
        this.isThemeDark = false;
      }
    });
  },
  mounted () { 
    this.defaultThemeColor = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'theme-dark'
    if (this.defaultThemeColor === 'theme-dark') {
        this.isThemeDark = true;
      } else {
        this.isThemeDark = false;
      }
    document.title= "Privacy Policy: Privacy Statement - Kee";
    var allMetaElements = document.getElementsByTagName('meta');
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera;
    if (this.isThemeDark) {
      fetch(this.darkUrl)
        .then(res => res.text())
        .then(html => this.dataset = html);
    } else {
      fetch(this.lightUrl)
        .then(res => res.text())
        .then(html => this.dataset = html);
    }
  }
}
</script>

<style>
.multi-lang-privacy {
  text-align: center;
  color: #777;
  font-size: 24px !important;
  font-weight:bold;
  margin-top: 4rem;
}
/* .linguval-privacy{
  position: relative;
  top:2px
} */
</style>
